 .header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #fff;
  }
  
  .header li {
    float: left;
    width: 200;
  }
  
  .header li a {
    display: block;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-weight: 500;

  }
  
 .header  li a:hover {
    background-color: #f8f7f7;
  }

  .header a.active {
    background-color: #f8f7f7;
    color: #1890ff;
  }

  .header a.logo {
    font-size: 25px;
    font-weight: bold;
    float: left;
  }
  

  .ant-carousel .slick-slide {
    text-align: center;
    height: 400px;
    line-height: 400px;
    background: #364d79;
    overflow: hidden;
  }
  
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }